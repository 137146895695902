var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mieles"}},[_c('v-app-bar',{staticClass:"px-1 mt-2",attrs:{"flat":"","color":"transparent","height":"64","min-height":"64"}},[_c('v-breadcrumbs',{staticClass:"pl-2 font-weight-medium body-1",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","single-line":"","prepend-inner-icon":"mdi-magnify","placeholder":"Escribe para buscar...","flat":"","solo":"","background-color":"white","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-3 text-none green--text text--lighten-5",attrs:{"depressed":"","color":"primary darken-1","to":"/mieles/nuevo"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nuevo")]),_c('v-icon',{attrs:{"color":"green lighten-5","small":"","right":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-container',{staticClass:"pt-0 px-5",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"white",attrs:{"search":_vm.search,"fixed-header":"","height":_vm.height,"headers":_vm.headers,"items":_vm.mieles,"items-per-page":20,"header-props":{
            'sort-by-text': 'Ordenar por'
        },"no-results-text":"No se encontraron resultados","no-data-text":"No hay información para mostrar","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text':'Filas por página',
        'pageText': '{0}-{1} de {2}',
        'items-per-page-all-text': 'Todas',
        'items-per-page-options': [
            20, 30, 45, -1
        ]
        }},on:{"click:row":_vm.handleClick}}),_c('transition',{attrs:{"name":"slide-fade"}},[_c('router-view',{key:_vm.$route.path,attrs:{"name":"mieles","height":_vm.height}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }